<template>
  <TheHeader />

  <main v-if="loaded" v-bind:class="[whichPart == 'A' ? 'partA' : 'partB']">
    <section class="container">
      <!-- Partie du haut contenant l'affichage du bouton, des statuts et des rendez-vous -->
      <div class="topPart row">
        <!-- Bloc bouton refus/informations -->
        <div class="left col-12 col-md-4" v-if="currentCase">
          <MediationButton :status="currentCase.status.idStatus" :whichPart="whichPart" @click="refuseMediation" />

          <!-- Bulle d'informations sous le bouton refuser la médiation -->
          <div class="informations infosPartB" v-if="currentCase && whichPart == 'B' && currentCase.status.idStatus >= 100 && currentCase.status.idStatus <= 299">
            <div>
              <p>
                <i class="icon-noun_User"></i>
                Monsieur/Madame
                <span>{{ currentCase.customerA.lastName }}</span>
                vous propose une médiation
              </p>
              <a target="_blank" href="https://letmegooglethat.com/?q=Qu%27est-ce+que+la+m%C3%A9diation+%3F">Qu'est ce que la médiation ?</a>
            </div>
          </div>
        </div>

        <!-- Bloc bancaire et bloc d'informations -->
        <div class="right col-12 col-md-8">
          <!-- Informations et paiement -->
          <div class="wrapper">
            <div class="informations bankInfos row" v-if="currentCase">
              <div id="titre" class="col-lg-5">
                <i class="icon-noun_Meeting"></i>
                <div>
                  <span class="title" v-if="whichPart == 'A' || 'B'">Demande de Médiation </span>
                  <p class="num-aff">Affaire n°{{ currentCase.sinisterNumber }}</p>
                </div>
              </div>

              <div class="text-choose-creneau top col-lg-6">
                <div v-if="checkStatusRdv(currentCase.status.idStatus)">
                  <p v-if="singleSlot && (currentCase.status.idStatus < 170 || (currentCase.status.idStatus >= 240 && currentCase.status.idStatus < 270))">
                    Choisissez une date <br />
                    et un créneau horaire
                  </p>
                  <p v-if="singleSlot && whichPart == 'B' && currentCase.status.idStatus >= 270 && currentCase.status.idStatus <= 279">
                    Choisissez 1 créneau <br />
                    parmis les 5 proposés
                  </p>
                  <p v-if="singleSlot && currentCase.status.idStatus >= 170 && whichPart == 'A'">Veuillez sélectionner 5 créneaux horaires</p>
                </div>
              </div>

              <div class="col-lg-12 row">
                <!-- Bloc statut, informations paiements et rendez-vous -->
                <Status :status="currentCase.status.idStatus" :whichPart="whichPart" />

                <!-- Bloc rendez-vous -->
                <RendezVous :idCase="currentCase.idCase" :status="currentCase.status.idStatus" :whichPart="whichPart" @update="updateCaseStatus" />
              </div>

              <!-- Paiement Stripe -->
              <div class="col-lg-7 bankInfos payment" v-if="currentCase && whichPart == 'B' && currentCase.status.idStatus >= 220 && currentCase.status.idStatus <= 229" id="customerB">
                <div v-if="stripePayment" id="info">
                  <stripe-checkout ref="checkoutRef" mode="payment" :pk="publishableKey" :line-items="lineItems" :success-url="successURL" :cancel-url="cancelURL" />
                  <button class="button" @click="submit">Procéder au paiement</button>
                  <div v-if="paymentError" style="color: #f30b0b; font-weight: 400">
                    {{ paymentErrorText }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Partie du bas contenant les blocs d'informations et de description -->
      <div class="bottomPart">
        <!-- Bloc d'informations de la partie A ou B (Selon la personne connectée) -->
        <div class="col-12 col-md-4 cardInfos" id="customerA">
          <!-- Header du bloc -->
          <div class="header">
            <div>
              <h4 class="firstTitle" id="titleCustomer">
                <i class="icon-noun_User"></i>
                Vos informations
              </h4>
              <button
                :disabled="!errorLN && !errorFN && !errorCE && !errorCT && !errorPC ? false : true"
                class="buttonCustomer"
                v-bind:class="[disabledInformations ? '' : 'saveChanges']"
                v-bind:id="[whichPart == 'A' ? `${currentCase.customerA.idCustomer}` : `${currentCase.customerB.idCustomer}`]"
                @click="saveOwnInformations($event)"
                v-if="(whichPart == 'A' && currentCase.status.idStatus >= 110 && currentCase.status.idStatus <= 119) || (whichPart == 'B' && currentCase.status.idStatus >= 220 && currentCase.status.idStatus <= 229)"
              >
                {{ textOwnInformations }}
              </button>
            </div>
          </div>
          <!-- Corps du bloc -->

          <div v-if="currentCase" class="infosCustomer">
            <form action="">
              <div v-bind:class="[errorLN ? 'missingElement' : '']">
                <label for="lastName">Nom</label>
                <input autocomplete="off" data-lpignore="true" data-form-type="other" v-bind:id="[whichPart == 'A' ? `lastNameA` : `lastNameB`]" type="text" v-model="ownLastName" :disabled="disabledInformations" />
              </div>
              <div v-bind:class="[errorFN ? 'missingElement' : '']">
                <label for="firstName">Prénom</label>
                <input autocomplete="off" data-lpignore="true" data-form-type="other" v-bind:id="[whichPart == 'A' ? `firstNameA` : `firstNameB`]" type="text" v-model="ownFirstName" :disabled="disabledInformations" />
              </div>
              <div v-bind:class="[errorCE ? 'missingElement' : '']">
                <label for="contactEmail">Email</label>
                <input autocomplete="off" data-lpignore="true" data-form-type="other" v-bind:id="[whichPart == 'A' ? `contactEmailA` : `contactEmailB`]" type="text" v-model="ownContactEmail" :disabled="disabledInformations" />
              </div>
              <div v-bind:class="[errorCT ? 'missingElement' : '']">
                <label for="contactTel">Téléphone</label>
                <input autocomplete="off" data-lpignore="true" data-form-type="other" v-bind:id="[whichPart == 'A' ? `contactTelA` : `contactTelB`]" type="text" v-model="ownContactTel" :disabled="disabledInformations" />
              </div>
              <div v-bind:class="[errorPC ? 'missingElement' : '']" class="postalCode">
                <label for="postalCode">Code postal</label>
                <input autocomplete="off" data-lpignore="true" data-form-type="other" v-bind:id="[whichPart == 'A' ? `postalCodeA` : `postalCodeB`]" type="text" v-model="ownPostalCode" :disabled="disabledInformations" />
              </div>
              <div v-bind:class="[errorC ? 'missingElement' : '']" class="city" v-if="whichPart == 'A'">
                <label for="city">Ville</label>
                <select id="selectCity" v-model="ownCity" v-if="!disabledInformations">
                  <option v-for="city in cities" :value="city.nomCommune" :key="city.codePostal">
                    {{ city.nomCommune }}
                  </option>
                </select>
                <input autocomplete="off" data-lpignore="true" data-form-type="other" v-bind:id="[whichPart == 'A' ? `postalCodeA` : `postalCodeB`]" type="text" v-model="ownCity" :disabled="disabledInformations" v-else />
              </div>
              <div v-if="saveOwnInformationsError">
                {{ saveOwnInformationsErrorText }}
              </div>
            </form>
          </div>
        </div>

        <!-- Bloc d'informations de la partie B (Uniquement pour la partie A) -->
        <div class="col-12 col-md-4 cardInfos" v-if="currentCase && whichPart == 'A'" id="customerB">
          <!-- Header du bloc -->
          <div class="header">
            <div>
              <h4 class="firstTitle" id="titleCustomer">
                <i class="icon-noun_User"></i>
                Partie adverse
              </h4>
              <button :disabled="!errorOLN && !errorOFN && !errorOCE && !errorOCT && !errorOPC ? false : true" class="buttonCustomer" v-bind:class="[disabledPartieAdverse ? '' : 'saveChanges']" v-bind:id="[whichPart == 'A' ? `${currentCase.customerB.idCustomer}` : ``]" @click="saveOtherPartInformations($event)" v-if="currentCase.status.idStatus >= 110 && currentCase.status.idStatus <= 119">
                {{ textOtherPart }}
              </button>
            </div>
          </div>
          <!-- Corps du bloc -->
          <div class="infosCustomer">
            <form action="">
              <div v-bind:class="[errorOLN ? 'missingElement' : '']">
                <label for="lastName">Nom</label>
                <input autocomplete="off" data-lpignore="true" data-form-type="other" id="lastNameB" type="text" v-model="otherLastName" :disabled="disabledPartieAdverse" />
              </div>
              <div v-bind:class="[errorOFN ? 'missingElement' : '']">
                <label for="firstName">Prénom</label>
                <input autocomplete="off" data-lpignore="true" data-form-type="other" id="firstNameB" type="text" v-model="otherFirstName" :disabled="disabledPartieAdverse" />
              </div>
              <div v-bind:class="[errorOCE ? 'missingElement' : '']">
                <label for="contactEmail">Email</label>
                <input autocomplete="off" data-lpignore="true" data-form-type="other" id="contactEmailB" type="text" v-model="otherContactEmail" :disabled="disabledPartieAdverse" />
              </div>
              <div v-bind:class="[errorOCT ? 'missingElement' : '']">
                <label for="contactTel">Téléphone</label>
                <input autocomplete="off" data-lpignore="true" data-form-type="other" id="contactTelB" type="text" v-model="otherContactTel" :disabled="disabledPartieAdverse" />
              </div>
              <div v-bind:class="[errorOPC ? 'missingElement' : '']" class="postalCode">
                <label for="postalCode">Code postal</label>
                <input autocomplete="off" data-lpignore="true" data-form-type="other" id="postalCodeB" type="text" v-model="otherPostalCode" :disabled="disabledPartieAdverse" />
              </div>
              <div v-if="saveOtherPartInformationsError">
                {{ saveOtherPartInformationsErrorText }}
              </div>
            </form>
          </div>
        </div>

        <!-- Bloc de description de l'affaire -->
        <div v-if="currentCase" class="col-12 col-md-4 descCase">
          <div class="header">
            <h4 class="mb-0 titleDesc">Description de l'affaire</h4>
          </div>
          <div class="contentDesc">
            <p>{{ currentCase.details }}</p>
          </div>
        </div>
      </div>
      <p style="margin-left: 15px; font-size: 12px; font-style: italic">Si vous remarquez des erreurs dans vos informations et que vous ne pouvez pas les modifier, merci de nous contacter à l'adresse mail suivante : <span style="font-weight: bold">mediation@avoloi.com</span></p>
    </section>
  </main>

  <!-- Si l'affaire et les informations ne sont pas chargées, afficher l'icône de chargement -->
  <main v-if="!loaded">
    <div class="container-load">
      <div class="center-load">
        <clip-loader :color="color" :size="size"></clip-loader>
      </div>
    </div>
  </main>

  <!-- Notification de l'expiration de la session -->
  <SessionExpired v-show="sessionExpired" />
  <!-- Notification pour valider les informations de la partie A et B lors du statut 110-119 -->
  <ValidateInformations
    v-show="askingToValidate"
    @validate="
      updateCaseStatus(220);
      consentGiven = true;
    "
    @cancel="askingToValidate = false"
  />

  <!-- Succès ou échec paiement -->
  <Success v-show="paymentSuccess" @validate="paymentSuccess = false" />
  <Cancel v-show="paymentCancel" @close="paymentCancel = false" />
</template>

<script>
import App from '../App.vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

import TheHeader from '../components/TheHeader.vue';
import ValidateInformations from '../components/ValidateInformations.vue';
import SessionExpired from '../components/SessionExpired.vue';
import MediationButton from '../components/MediationButton.vue';
import Status from '../components/Status.vue';
import RendezVous from '../components/RendezVous.vue';

import Success from '../components/Success.vue';
import Cancel from '../components/Cancel.vue';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
SwiperCore.use([Navigation]);

import { StripeCheckout } from '@vue-stripe/vue-stripe';

const codesPostaux = require('codes-postaux');

export default {
  name: 'Accueil',
  components: {
    ClipLoader,
    TheHeader,
    ValidateInformations,
    SessionExpired,
    Swiper,
    SwiperSlide,
    MediationButton,
    Status,
    RendezVous,

    StripeCheckout,
    Success,
    Cancel,
  },
  data() {
    this.publishableKey = 'pk_test_51JiDNDCAExFhrBT9unGzcCkSM04hDtjw8FAjfoKgBpIqcMYsmNiXAGgHdnlQbs4VCl9Fo9GxcDZCLHmw5EDXalXz00X14KjEql';
    return {
      lineItems: [
        {
          price: 'price_1KIvxVCAExFhrBT9Uu0oojgo',
          quantity: 1,
        },
      ],
      successURL: '',
      cancelURL: '',
      stripePayment: false,
      paymentSuccess: false,
      paymentCancel: false,
      paymentError: false,
      paymentErrorText: null,

      // Notifications ValidateInformations
      consentGiven: false,
      askingToValidate: false,

      // Notifications SessionExpired
      sessionExpired: false,
      isExpired: false,

      // Infos de l'affaire
      idCase: null,
      whichPart: null,
      currentCase: null,

      // Chargement
      size: '50px',
      color: '#55183d',
      loaded: false,

      // Partie modification
      disabledInformations: true,
      disabledPartieAdverse: true,
      textOwnInformations: 'Enregistrer',
      textOtherPart: 'Enregistrer',

      saveOwnInformationsError: false,
      saveOwnInformationsErrorText: '',
      saveOtherPartInformationsError: false,
      saveOtherPartInformationsErrorText: '',

      // Rendez-vous
      availabilities: null,
      availableSlots: null,
      singleSlot: true,
      selectedSlot: [],
      selectedSlotDate: [],
      monthOptions: {
        month: 'long',
        day: 'numeric',
      },
      dayOption: {
        weekday: 'long',
      },
      options: {
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      },
      rdvA: [130, 131, 132, 150, 151, 152, 170, 171, 172],
      rdvB: [240, 241, 242, 260, 261, 262, 270, 271, 272],
      rdvTakenA: [139, 159, 270, 271, 272, 279],
      rdvTakenB: [249, 269, 279],
      dateRdv: null,

      // Partie connectée (A ou B)
      ownLastName: null,
      errorLN: false,
      ownFirstName: null,
      errorFN: false,
      ownContactEmail: null,
      errorCE: false,
      ownContactTel: null,
      errorCT: false,
      ownPostalCode: null,
      errorPC: false,
      cities: [],
      selectedCity: null,
      ownCity: null,
      errorC: false,

      // Partie à modifier (uniquement B)
      otherLastName: null,
      errorOLN: false,
      otherFirstName: null,
      errorOFN: false,
      otherContactEmail: null,
      errorOCE: false,
      otherContactTel: null,
      errorOCT: false,
      otherPostalCode: null,
      errorOPC: false,
    };
  },
  watch: {
    // Permet d'ajouter un chargement une fois l'affaire récuperée et de bind chaque valeur des customers à une variable
    currentCase: {
      deep: true,
      async handler() {
        if (this.currentCase) {
          // await new Promise(resolve => setTimeout(resolve, 1000));
          this.loaded = true;
        }
        if (this.whichPart == 'A' && !this.consentGiven) {
          //#region Erreurs A
          this.ownLastName = this.currentCase.customerA.lastName;
          if (this.ownLastName == null) {
            this.ownLastName = '';
            this.errorLN = true;
          }
          this.ownFirstName = this.currentCase.customerA.firstName;
          if (this.ownFirstName == null) {
            this.ownFirstName = '';
            this.errorFN = true;
          }
          this.ownContactEmail = this.currentCase.customerA.contactEmail;
          if (this.ownContactEmail == null) {
            this.ownContactEmail = '';
            this.errorCE = true;
          }
          this.ownContactTel = this.currentCase.customerA.contactTel;
          if (this.ownContactTel == null) {
            this.ownContactTel = '';
            this.errorCT = true;
          }
          this.ownPostalCode = this.currentCase.customerA.postalCode;
          if (this.ownPostalCode == null) {
            this.ownPostalCode = '';
            this.errorPC = true;
            this.cities = [{ nomCommune: 'Pas de résultats' }];
          }
          this.ownCity = this.currentCase.customerA.city;
          if (this.ownCity == null) {
            this.ownCity = '';
            this.errorC = true;
          }
          //#endregion
          //#region Erreurs B
          this.otherLastName = this.currentCase.customerB.lastName;
          if (this.otherLastName == null) {
            this.otherLastName = '';
            this.errorOLN = true;
          }
          this.otherFirstName = this.currentCase.customerB.firstName;
          if (this.otherFirstName == null) {
            this.otherFirstName = '';
            this.errorOFN = true;
          }
          this.otherContactEmail = this.currentCase.customerB.contactEmail;
          if (this.otherContactEmail == null) {
            this.otherContactEmail = '';
            this.errorOCE = true;
          }
          this.otherContactTel = this.currentCase.customerB.contactTel;
          if (this.otherContactTel == null) {
            this.otherContactTel = '';
            this.errorOCT = true;
          }
          this.otherPostalCode = this.currentCase.customerB.postalCode;
          if (this.otherPostalCode == null) {
            this.otherPostalCode = '';
            this.errorOPC = true;
          }
          if (this.otherContactEmail == null && this.otherContactTel == null) {
            this.otherContactEmail = '';
            this.otherContactTel = '';
            this.errorOCE = this.errorOCT = true;
          }
          if (this.otherContactEmail == undefined && this.otherContactTel == undefined) {
            this.otherContactEmail = '';
            this.otherContactTel = '';
            this.errorOCE = this.errorOCT = true;
          }
          if (this.otherContactEmail == '' && this.otherContactTel == '') {
            this.errorOCE = this.errorOCT = true;
          }
          //#endregion
        }
        if (this.whichPart == 'B') {
          this.ownLastName = this.currentCase.customerB.lastName;
          if (this.ownLastName == null) {
            this.ownLastName = '';
            this.errorLN = true;
          }
          this.ownFirstName = this.currentCase.customerB.firstName;
          if (this.ownFirstName == null) {
            this.ownFirstName = '';
            this.errorFN = true;
          }
          this.ownContactEmail = this.currentCase.customerB.contactEmail;
          if (this.ownContactEmail == null) {
            this.ownContactEmail = '';
            this.errorCE = true;
          }
          this.ownContactTel = this.currentCase.customerB.contactTel;
          if (this.ownContactTel == null) {
            this.ownContactTel = '';
            this.errorCT = true;
          }
          this.ownPostalCode = this.currentCase.customerB.postalCode;
        }
      },
    },

    // Erreur complétion partie A ou B
    ownLastName: async function () {
      if (this.ownLastName.length == '') this.errorLN = true;
      else this.errorLN = false;
    },
    ownFirstName: async function () {
      if (this.ownFirstName.length == '') this.errorFN = true;
      else this.errorFN = false;
    },
    ownContactEmail: async function () {
      var regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regexEmail.test(String(this.ownContactEmail).toLowerCase())) this.errorCE = true;
      else this.errorCE = false;
    },
    ownContactTel: async function () {
      if (this.ownContactTel.length > 10) {
        this.ownContactTel = this.ownContactTel.substring(0, 10);
      }
      if (this.ownContactTel.length != 10 || isNaN(this.ownContactTel)) this.errorCT = true;
      else this.errorCT = false;
    },
    ownPostalCode: async function () {
      if (this.ownPostalCode.length > 5) {
        this.ownPostalCode = this.ownPostalCode.substring(0, 5);
      } else if (this.ownPostalCode.length == 5 && !isNaN(this.ownPostalCode) && typeof parseInt(this.ownPostalCode) == 'number') {
        this.searchCities();
      } else {
        this.errorPC = true;
      }
    },
    ownCity: async function () {
      if (this.whichPart == 'A') {
        if (this.ownCity != 'Pas de résultats') this.errorC = false;
        else this.errorC = true;
      }
    },

    cities: async function () {
      this.ownCity = this.cities[0].nomCommune;
    },
    //#region Erreur complétion partie B
    otherLastName: async function () {
      if (this.otherLastName.length == '') this.errorOLN = true;
      else this.errorOLN = false;
    },

    otherFirstName: async function () {
      if (this.otherFirstName.length == '') this.errorOFN = true;
      else this.errorOFN = false;
    },

    otherContactEmail: async function () {
      var regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.errorOCT == false && this.otherContactTel.length != 0) {
        if (this.otherContactEmail.length == 0) {
          this.errorOCE = false;
        } else {
          if (!regexEmail.test(String(this.otherContactEmail).toLowerCase())) {
            this.errorOCE = true;
          } else {
            this.errorOCE = false;
          }
        }
      } else {
        if (this.otherContactEmail.length == 0 && this.otherContactTel.length == 0) {
          this.errorOCE = true;
          this.errorOCT = true;
        } else {
          if (!regexEmail.test(String(this.otherContactEmail).toLowerCase())) {
            if (this.otherContactEmail.length != 0) {
              this.errorOCE = true;
              this.errorOCT = true;
            } else {
              this.errorOCE = false;
            }
          } else {
            if (this.otherContactTel.length != 10 || isNaN(this.otherContactTel)) {
              this.errorOCE = false;
              this.errorOCT = false;
            } else if (this.errorOCT == false) {
              this.errorOCE = false;
            } else if (!regexEmail.test(String(this.otherContactEmail).toLowerCase()) && (this.otherContactTel.length != 10 || isNaN(this.otherContactTel))) {
              this.errorOCE = true;
              this.errorOCT = true;
            }
          }
        }
      }
    },

    otherContactTel: async function () {
      if (this.otherContactTel.length > 10) {
        this.otherContactTel = this.otherContactTel.substring(0, 10);
      }
      var regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.errorOCE == false && this.otherContactEmail.length != 0) {
        if (this.otherContactTel.length == 0) {
          this.errorOCT = false;
        } else {
          if (this.otherContactTel.length != 10 || isNaN(this.otherContactTel)) {
            this.errorOCT = true;
          } else {
            this.errorOCT = false;
          }
        }
      } else {
        if (this.otherContactEmail.length == 0 && this.otherContactTel.length == 0) {
          this.errorOCE = true;
          this.errorOCT = true;
        } else {
          if (this.otherContactTel.length != 10 || isNaN(this.otherContactTel)) {
            if (this.otherContactTel.length != 0) {
              this.errorOCT = true;
              this.errorOCE = true;
            } else {
              this.errorOCT = true;
            }
          } else {
            if (!regexEmail.test(String(this.otherContactEmail).toLowerCase())) {
              this.errorOCE = false;
              this.errorOCT = false;
            } else if (this.errorOCE == false) {
              this.errorOCT = false;
            } else if (!regexEmail.test(String(this.otherContactEmail).toLowerCase()) && (this.otherContactTel.length != 10 || isNaN(this.otherContactTel))) {
              this.errorOCE = true;
              this.errorOCT = true;
            }
          }
        }
      }
    },

    otherPostalCode: async function () {},
    //#endregion

    // Afficher la notification de session expirée si le token est arrivé/ou arrive à date
    isExpired: async function () {
      if (this.isExpired == true) this.sessionExpired = true;
      else {
        await new Promise((resolve) => setTimeout(resolve, Math.abs(this.isExpired)));
        this.sessionExpired = true;
      }
    },

    // Gère les boutons de modification
    disabledInformations: async function () {
      if (this.disabledInformations) this.textOwnInformations = 'Modifier';
      else this.textOwnInformations = 'Enregistrer';
      if (this.disabledInformations && this.disabledPartieAdverse) this.askingToValidate = true;
    },
    disabledPartieAdverse: async function () {
      if (this.disabledPartieAdverse) this.textOtherPart = 'Modifier';
      else this.textOtherPart = 'Enregistrer';
      if (this.disabledInformations && this.disabledPartieAdverse) this.askingToValidate = true;
    },

    // Afficher la confirmation des informations
    askingToValidate: async function () {
      if (!this.askingToValidate && !this.consentGiven) {
        this.disabledInformations = false;
        this.disabledPartieAdverse = false;
      }
    },
  },
  async mounted() {
    // Vérifier l'expiration du token
    this.isExpired = await App.methods.verifyToken();
    if (this.isExpired == true) return;

    // Récupérer les informations du token
    var base64 = localStorage.getItem('AVOLOI_MEDIATION_AUTH_TOKEN').split('.')[1];
    var jwtPayload = JSON.parse(Buffer.from(base64, 'base64'));
    this.idCase = jwtPayload.idCase;
    this.whichPart = jwtPayload.whichPart;

    // Récupérer l'affaire correspondante à l'ID du token
    this.currentCase = await this.getCases(this.idCase);

    // Activier les moifications des infos seulement si les conditions sont respectées
    if (this.whichPart == 'A') {
      if (this.currentCase.status.idStatus >= 110 && this.currentCase.status.idStatus <= 119) {
        this.disabledInformations = false;
        this.disabledPartieAdverse = false;
      }
    }
    if (this.whichPart == 'B') {
      if (this.currentCase.status.idStatus >= 220 && this.currentCase.status.idStatus <= 229) {
        this.disabledInformations = false;
        this.disabledPartieAdverse = false;
      }
    }

    if (this.whichPart == 'B' && this.currentCase.status.idStatus >= 220 && this.currentCase.status.idStatus <= 229) {
      const jwt = require('jsonwebtoken');
      var tokenPayload = await {
        exp: jwtPayload.exp,
        idCase: jwtPayload.idCase,
        idCustomer: jwtPayload.idCustomer,
        whichPart: jwtPayload.whichPart,
        payment: true,
      };
      var stripeToken = await new Promise((resolve, reject) => {
        var privateKey = process.env.VUE_APP_TOKEN_SECRET_KEY;
        var token = jwt.sign(tokenPayload, privateKey, { algorithm: process.env.VUE_APP_TOKEN_ALGORITHM });
        var tokenModel = {
          token: token,
        };
        return resolve(tokenModel);
      });

      this.successURL = window.location.origin + '/accueil#' + stripeToken.token;
      this.cancelURL = window.location.origin + '/accueil#cancel';

      if (location.hash.substring(1) == 'cancel') {
        this.paymentCancel = true;
        this.stripePayment = true;
      } else if (location.hash.substring(1).length != 0) {
        try {
          var decoded = jwt.verify(location.hash.substring(1), process.env.VUE_APP_TOKEN_SECRET_KEY);
          if (decoded.idCase == jwtPayload.idCase && decoded.idCustomer == jwtPayload.idCustomer && decoded.idCustomer == jwtPayload.idCustomer && decoded.payment == true) {
            this.paymentSuccess = true;
            this.disabledInformations = !this.disabledInformations;
            this.updateCaseStatus(130);
          }
        } catch (err) {
          this.stripePayment = true;
          console.log(err);
        }
      } else {
        this.stripePayment = true;
      }
    }
  },
  methods: {
    submit() {
      if (!this.disabledInformations) {
        this.paymentError = true;
        this.paymentErrorText = "Merci d'enregistrer vos informations personnelles avant de procéder au paiement.";
        return false;
      } else this.paymentError = false;
      this.$refs.checkoutRef.redirectToCheckout();
    },

    // Récupérer l'affaire selon l'ID du token
    getCases(id) {
      return this.$axiosConfigInstance
        .get('/cases', {
          params: {
            idCase: id,
          },
        })
        .then((response) => {
          return response.data.cases[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Refuser la médiation
    refuseMediation() {
      if (this.whichPart == 'A') var status = 10;
      else var status = 20;
      this.updateCaseStatus(status);
    },

    // Sauvegarder les informations de la partie connectée (A ou B)
    saveOwnInformations(event) {
      this.disabledInformations = !this.disabledInformations;
      if (this.disabledInformations) this.saveCustomer(event.target.id);
    },

    searchCities() {
      this.cities = codesPostaux.find(this.ownPostalCode);
      if (this.cities.length == 0) {
        this.errorC = true;
        this.errorPC = true;
        this.cities = [{ nomCommune: 'Pas de résultats' }];
      } else {
        this.errorC = false;
        this.errorPC = false;
      }
    },

    // Sauvegarder les informations de la partie adverse (A uniquement)
    saveOtherPartInformations(event) {
      this.disabledPartieAdverse = !this.disabledPartieAdverse;
      if (this.disabledPartieAdverse) this.saveCustomer(event.target.id);
    },

    // Enregistrer les infos en BDD
    saveCustomer(customerId) {
      var lastName;
      var firstName;
      var contactTel;
      var contactEmail;
      var postalCode;
      var city;
      if ((this.whichPart == 'A' && customerId == this.currentCase.customerA.idCustomer) || (this.whichPart == 'B' && customerId == this.currentCase.customerB.idCustomer)) {
        lastName = this.ownLastName;
        firstName = this.ownFirstName;
        contactTel = this.ownContactTel;
        contactEmail = this.ownContactEmail;
        postalCode = this.ownPostalCode;
        if (this.whichPart == 'A') city = this.ownCity;
      } else {
        lastName = this.otherLastName;
        firstName = this.otherFirstName;
        contactTel = this.otherContactTel;
        contactEmail = this.otherContactEmail;
        postalCode = this.otherPostalCode;
      }
      this.$axiosConfigInstance
        .put(`/customers/${customerId}`, {
          lastName: lastName,
          firstName: firstName,
          contactTel: contactTel,
          contactEmail: contactEmail,
          postalCode: postalCode,
          ...(city ? { city: city } : {}),
        })
        .catch((error) => {
          if (this.whichPart == 'A' && customerId == this.currentCase.customerA.idCustomer) {
            this.ownLastName = this.currentCase.customerA.lastName;
            this.ownFirstName = this.currentCase.customerA.firstName;
            this.ownContactTel = this.currentCase.customerA.contactTel;
            this.ownContactEmail = this.currentCase.customerA.contactEmail;
            this.ownPostalCode = this.currentCase.customerA.postalCode;
            this.ownCity = this.currentCase.customerA.city;
          } else if (this.whichPart == 'A' && customerId == this.currentCase.customerB.idCustomer) {
            this.otherLastName = this.currentCase.customerB.lastName;
            this.otherFirstName = this.currentCase.customerB.firstName;
            this.otherContactTel = this.currentCase.customerB.contactTel;
            this.otherContactEmail = this.currentCase.customerB.contactEmail;
            this.otherPostalCode = this.currentCase.customerB.postalCode;
          } else if (this.whichPart == 'B' && customerId == this.currentCase.customerB.idCustomer) {
            this.ownLastName = this.currentCase.customerB.lastName;
            this.ownFirstName = this.currentCase.customerB.firstName;
            this.ownContactTel = this.currentCase.customerB.contactTel;
            this.ownContactEmail = this.currentCase.customerB.contactEmail;
            this.ownPostalCode = this.currentCase.customerB.postalCode;
          }
          console.log(error);
        });
    },

    // Mettre à jour le statut de l'affaire
    updateCaseStatus(status) {
      this.$axiosConfigInstance
        .put(`/cases/${this.currentCase.idCase}/status`, {
          status: status,
        })
        .then((response) => {
          this.selectedSlot = [];
          this.selectedSlotDate = [];
          this.currentCase.status.idStatus = response.data.idStatus;
          this.currentCase.status.name = response.data.name;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    checkStatusRdv(status) {
      if (this.whichPart == 'A') return this.rdvA.indexOf(status) > -1;
      if (this.whichPart == 'B') return this.rdvB.indexOf(status) > -1;
    },
  },
};
</script>

<style lang="scss">
.payment {
  @media (min-width: 992px) {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    right: 0;
  }
}

#selectCity {
  border: 0;
  border-bottom: 1px solid #e4e4e4;
  font-weight: 300;
  font-size: 14px;
  color: #000;
  padding: 0.25rem 0.5rem;
  transition: all 0.2s ease-in-out;
  background-color: #eef0f5;
}
</style>
