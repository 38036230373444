<template>
  <!-- Bloc statut -->
  <div id="statut" class="col-lg-5">
    <!-- Statut succès -->
    <div v-if="status == 1">
      <p class="green">
        <span class="font">Statut :</span>
        <i class="icon-picto-check"></i>
        <span>Vous avez trouvé un terrain d'entente</span>
      </p>
    </div>

    <!-- Statuts échecs -->
    <div v-if="status >= 10 && status <= 99">
      <p class="red">
        <span class="font">Statut : </span>
        <i class="icon-picto-croix"></i>
        <span v-if="status == 10 && whichPart == 'A'">Vous n'avez pas confirmé vos informations</span>
        <span v-if="status == 10 && whichPart == 'B'">La partie adverse n'a pas donné suite à la demande de médiation</span>

        <span v-if="status == 20 && whichPart == 'A'">La partie adverse n'a pas confirmé ses informations</span>
        <span v-if="status == 20 && whichPart == 'B'">Vous n'avez pas confirmé vos informations</span>

        <span v-if="status == 30 && whichPart == 'A'">Vous n'avez pas donné suite à votre rendez-vous d'exploration</span>
        <span v-if="status == 30 && whichPart == 'B'">La partie adverse n'a pas donné suite à son rendez-vous d'exploration</span>

        <span v-if="status == 40 && whichPart == 'A'">La partie adverse n'a pas donné suite à son rendez-vous d'exploration</span>
        <span v-if="status == 40 && whichPart == 'B'">Vous n'avez pas donné suite à votre rendez-vous d'exploration</span>

        <span v-if="status == 50 && whichPart == 'A'">Vous n'avez pas donné suite à votre rendez-vous</span>
        <span v-if="status == 50 && whichPart == 'B'">La partie adverse n'a pas donné suite à son rendez-vous</span>

        <span v-if="status == 60 && whichPart == 'A'">La partie adverse n'a pas donné suite à son rendez-vous</span>
        <span v-if="status == 60 && whichPart == 'B'">Vous n'avez pas donné suite à votre rendez-vous</span>

        <span v-if="status == 70">Le rendez-vous collectif n'a pas eu lieu</span>

        <span v-if="status == 80 && whichPart == 'A'">Vous avez quitté la médiation</span>
        <span v-if="status == 80 && whichPart == 'B'">La partie adverse a quitté la médiation</span>

        <span v-if="status == 90 && whichPart == 'A'">La partie adverse a quitté la médiation</span>
        <span v-if="status == 90 && whichPart == 'B'">Vous avez quitté la médiation</span>
      </p>
    </div>

    <!-- Statuts Recherche partie A -->
    <div v-if="status >= 110 && status <= 119">
      <p class="red" v-if="whichPart == 'A'">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente de renseignements de votre part</span>
      </p>
      <p class="orange" v-if="whichPart == 'B'">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente de renseignements de la partie adverse</span>
      </p>
    </div>

    <!-- Statuts Rendez-vous exploration partie A -->
    <div v-if="status >= 130 && status <= 139">
      <p class="red" v-if="whichPart == 'A' && status < 139">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente de votre rendez-vous d'exploration</span>
      </p>
      <p class="green" v-else-if="whichPart == 'A' && status == 139">
        <span class="font">Statut :</span>
        <i class="icon-picto-check"></i>
        <span>En attente de votre rendez-vous d'exploration</span>
      </p>
      <p class="orange" v-if="whichPart == 'B'">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente du rendez-vous de la partie adverse</span>
      </p>
    </div>

    <!-- Statuts Rendez-vous partie A -->
    <div v-if="status >= 150 && status <= 159">
      <p class="red" v-if="whichPart == 'A' && status < 159">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        En attente de votre rendez-vous individuel
      </p>
      <p class="green" v-else-if="whichPart == 'A' && status == 159">
        <span class="font">Statut :</span>
        <i class="icon-picto-check"></i>
        <span>En attente de votre rendez-vous individuel</span>
      </p>
      <p class="orange" v-if="whichPart == 'B'">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente du rendez-vous de la partie adverse</span>
      </p>
    </div>

    <!-- Statuts Rendez-vous collectif partie A -->
    <div v-if="status >= 170 && status <= 179">
      <p class="red" v-if="whichPart == 'A' && status < 179">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span
          >En attente de votre
          <br />
          sélection des créneaux pour le rendez-vous collectif
        </span>
      </p>
      <p class="orange" v-else-if="whichPart == 'A' && status == 179">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente de sélection d'un créneau par la partie adverse</span>
      </p>
      <p class="orange" v-if="whichPart == 'B'">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span
          >La partie adverse
          <br />
          sélectionne des créneaux pour le rendez-vous collectif</span
        >
      </p>
    </div>

    <!-- Statuts Recherche partie B -->
    <div v-if="status >= 220 && status <= 229">
      <p class="red" v-if="whichPart == 'B'">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente de renseignements de votre part</span>
      </p>
      <p class="orange" v-if="whichPart == 'A'">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente du consentement de la partie adverse</span>
      </p>
    </div>

    <!-- Statuts Rendez-vous exploration partie B -->
    <div v-if="status >= 240 && status <= 249">
      <p class="red" v-if="whichPart == 'B' && status < 249">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente de votre rendez-vous d'exploration</span>
      </p>
      <p class="green" v-else-if="whichPart == 'B' && status == 249">
        <span class="font">Statut :</span>
        <i class="icon-picto-check"></i>
        <span>En attente de votre rendez-vous d'exploration</span>
      </p>
      <p class="orange" v-if="whichPart == 'A'">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente du rendez-vous de la partie adverse</span>
      </p>
    </div>

    <!-- Statuts Rendez-vous partie B -->
    <div v-if="status >= 260 && status <= 269">
      <p class="red" v-if="whichPart == 'B' && status < 269">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente de votre rendez-vous individuel</span>
      </p>
      <p class="green" v-else-if="whichPart == 'B' && status == 269">
        <span class="font">Statut :</span>
        <i class="icon-picto-check"></i>
        <span>En attente de votre rendez-vous individuel</span>
      </p>
      <p class="orange" v-if="whichPart == 'A'">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente du rendez-vous de la partie adverse</span>
      </p>
    </div>

    <!-- Statuts Rendez-vous collectif partie B -->
    <div v-if="status >= 270 && status <= 279">
      <p class="red" v-if="whichPart == 'B' && status < 279">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente de votre sélection du créneau pour le rendez-vous collectif</span>
      </p>
      <p class="green" v-if="status == 279">
        <span class="font">Statut :</span>
        <i class="icon-picto-check"></i>
        <span>En attente du rendez-vous collectif</span>
      </p>
      <p class="orange" v-if="whichPart == 'A' && status < 279">
        <span class="font">Statut :</span>
        <i class="icon-noun_Information"></i>
        <span>En attente de confirmation d'un créneau de la partie adverse</span>
      </p>
    </div>

    <!-- Partie A -->
    <div class="tipsBank" v-if="whichPart == 'A' && status >= 130 && status <= 139">Ce RDV durera 15 min, le médiateur vous contactera par téléphone.</div>
    <div class="tipsBank" v-if="whichPart == 'A' && status >= 150 && status <= 159">Ce RDV durera 1 heure, le médiateur vous contactera par téléphone.</div>
    <div class="tipsBank" v-if="whichPart == 'A' && status >= 170 && status <= 179">Ce RDV durera 1 heure, le médiateur vous contactera par téléphone.</div>

    <!-- Partie B -->
    <div class="tipsBank" v-if="whichPart == 'B' && status >= 240 && status <= 249">Ce RDV durera 15 min, le médiateur vous contactera par téléphone.</div>
    <div class="tipsBank" v-if="whichPart == 'B' && status >= 260 && status <= 269">Ce RDV durera 1 heure, le médiateur vous contactera par téléphone.</div>
    <div class="tipsBank" v-if="status >= 270 && status <= 279">Ce RDV durera 1 heure, le médiateur vous contactera par téléphone.</div>

    <!-- Informations concernant le paiement -->
    <div class="tipsBank" v-if="whichPart == 'B' && status >= 220 && status <= 229">Des frais de dossier/procédure d'une hauteur de 49€ vous seront débités. Si la procédure de médiation n'aboutie pas, vous serez remboursé de cette somme.</div>
  </div>
</template>

<script>
export default {
  name: 'Status',
  props: {
    status: { type: String },
    whichPart: { type: String },
  },
};
</script>
